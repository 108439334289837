import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/HorizontalScrollView/Indicator.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-7a2c6e72"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flex gap-2 justify-center mt-4 md:hidden" };
import { nextTick, onBeforeUnmount } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  setup(__props) {
    const views = ref(null);
    const viewCount = ref(0);
    const viewIndex = ref(0);
    const hasFocus = ref(false);
    const lastKnownOffsetX = ref(0);
    const currentOffsetX = ref(0);
    const touchStartX = ref(0);
    const animationTimeout = ref(void 0);
    onMounted(() => {
      window.addEventListener("wheel", handleScroll);
      window.addEventListener("resize", handleResize);
      nextTick(updateViewCount);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("resize", handleResize);
    });
    function handleResize() {
      updateViewCount();
    }
    function handleScroll(e) {
      if (hasFocus.value && views.value) {
        const total = views.value.scrollWidth - views.value.clientWidth;
        const deltaX = e.deltaX;
        let newOffsetX = currentOffsetX.value - deltaX;
        newOffsetX = Math.max(newOffsetX, 0);
        newOffsetX = Math.min(newOffsetX, total);
        currentOffsetX.value = newOffsetX;
        updateOffset(newOffsetX);
        updateIndicators(newOffsetX);
      }
    }
    function handleTouchMove(e) {
      e.preventDefault();
      if (hasFocus.value && views.value) {
        const startX = touchStartX.value;
        const currentX = getTouchPositionX(e);
        const offsetX = currentX - startX;
        const total = views.value.scrollWidth - views.value.clientWidth;
        const newOffsetX = clamp(currentOffsetX.value - offsetX, 0, total);
        updateOffset(newOffsetX);
        updateIndicators(newOffsetX);
      }
    }
    function handleScrollToIndex(index) {
      if (!views.value) {
        return;
      }
      const total = views.value.scrollWidth;
      const totalViewWidth = views.value.clientWidth * viewCount.value;
      const totalPadding = total - totalViewWidth;
      const padding = totalPadding / (viewCount.value - 1);
      const newOffsetX = views.value.clientWidth * index + padding * index;
      updateAnimationClasses();
      currentOffsetX.value = newOffsetX;
      lastKnownOffsetX.value = newOffsetX;
      updateOffset(newOffsetX);
      updateIndicators(newOffsetX);
    }
    function handleMouseEnter() {
      currentOffsetX.value = lastKnownOffsetX.value;
      hasFocus.value = true;
    }
    function handleMouseLeave() {
      hasFocus.value = false;
      lastKnownOffsetX.value = currentOffsetX.value;
    }
    function handleTouchStart(e) {
      currentOffsetX.value = lastKnownOffsetX.value;
      touchStartX.value = getTouchPositionX(e);
      hasFocus.value = true;
    }
    function handleTouchEnd() {
      hasFocus.value = false;
      if (!views.value) {
        return;
      }
      lastKnownOffsetX.value = views.value.style.transform ? translationToVector(views.value.style.transform).x * -1 : 0;
      currentOffsetX.value = lastKnownOffsetX.value;
    }
    function updateOffset(offset) {
      if (!views.value) {
        return;
      }
      views.value.style.transform = `translate3d(${offset * -1}px, 0, 0)`;
    }
    function updateIndicators(offsetX) {
      if (!views.value) {
        return;
      }
      const total = views.value.scrollWidth;
      viewIndex.value = Math.round(offsetX / total * viewCount.value);
    }
    function updateAnimationClasses() {
      if (!views.value) {
        return;
      }
      views.value.classList.add("animated");
      clearTimeout(animationTimeout.value);
      animationTimeout.value = setTimeout(() => {
        if (!views.value) {
          return;
        }
        views.value.classList.remove("animated");
      }, 200);
    }
    function updateViewCount() {
      if (!views.value) {
        return;
      }
      viewCount.value = views.value.children.length;
    }
    function translationToVector(translation) {
      const values = translation.split(/\w+\(|\);?/gim)[1].split(/,\s?/g).map(parseFloat);
      const x = values[0] || 0;
      const y = values[1] || 0;
      const z = values[2] || 0;
      return { x, y, z };
    }
    function getTouchPositionX(e) {
      const touch = e.touches[0] || e.changedTouches[0];
      return touch.pageX;
    }
    function clamp(val, min, max) {
      return Math.min(Math.max(val, min), max);
    }
    return (_ctx, _cache) => {
      const _component_AppHorizontalScrollViewIndicator = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
          class: "overflow-x-hidden hide-scrollbar",
          onMouseenter: handleMouseEnter,
          onMouseleave: handleMouseLeave,
          onTouchstart: handleTouchStart,
          onTouchend: handleTouchEnd,
          onTouchmove: handleTouchMove
        }, [
          _createElementVNode("div", {
            ref_key: "views",
            ref: views,
            class: "flex md:grid grid-cols-12 gap-6 views"
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 512)
        ], 32),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(viewCount), (index) => {
            return _openBlock(), _createBlock(_component_AppHorizontalScrollViewIndicator, {
              key: `horizontal-scroll-view-indicator-${index}`,
              active: _unref(viewIndex) === index - 1,
              onClick: ($event) => handleScrollToIndex(index - 1)
            }, null, 8, ["active", "onClick"]);
          }), 128))
        ])
      ]);
    };
  }
});
